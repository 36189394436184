import React, { useId, useContext, useEffect, useMemo, useState } from "react";

import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { RiFileExcel2Line } from "@remixicon/react";
import { base_url } from "../../config";
import axios from "axios";
import { Box, MenuItem, Tooltip } from "@mui/material";
import RegistrationContext from "../../contexts/RegistrationContext";
import SidePanel from "../../components/SidePanel/SidePanel";
import "./style.css";
import BadgesFilter from "../../components/BadgesFilter/BadgesFilter";
import FileUpload from "../../components/FileUpload/FileUpload";
import Loading from "../../pages/Loading/Loading";
import { Copy } from "lucide-react";

const CopyButton = ({ text }) => {
  return (
    <div className="flex gap-2 items-center">
      {text}

      <div
        onClick={(e) => {
          navigator.clipboard.writeText(text);
        }}
        className="cursor-pointer"
      >
        <Tooltip title="Copy" placement="right">
          <Copy size={20} />
        </Tooltip>
      </div>
    </div>
  );
};

const InvoiceDetails = ({ data, onSave }) => {
  const initBackendFileInvoice = {
    invoice_file_id: null,
    invoice_filename: null,
  };
  const { setError } = useContext(RegistrationContext);
  const [fileInvoice, setFileInvoice] = useState(null);
  const [backendFileInvoice, setBackendFileInvoice] = useState(
    initBackendFileInvoice
  );
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    // This executes when user clicks save file
    setBackendFileInvoice({
      invoice_file_id: data.invoice_file_id,
      invoice_filename: data.invoice_filename,
    });
    setFileInvoice(null);
    setIsLoading(false);
  }, [data.invoice_file_id, data.invoice_filename]);

  const hasChanged = () => {
    return (
      fileInvoice !== null ||
      backendFileInvoice.invoice_file_id !== data.invoice_file_id
    );
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className="container flex flex-col justify-center py-8 mx-auto md:h-full lg:py-0 text-left">
      <fieldset
        className="mb-12 w-fit border-solid border-2 rounded-md p-3.5"
        style={{ borderColor: "#f0a806", minWidth: "367px" }}
      >
        <legend className="px-1">Datos cliente</legend>

        <table>
          <tbody>
            <tr>
              <td>
                <CopyButton text={data.full_name} />
                {data.recipient_name &&
                  data.recipient_name !== data.full_name && (
                    <CopyButton text={data.recipient_name} />
                  )}
                {data.institution && <CopyButton text={data.institution} />}
                <CopyButton text={data.address1} />
                {data.address2 && <CopyButton text={data.address2} />}
                <CopyButton text={data.country_exp} />
                {data.tax_id && <CopyButton text={data.tax_id} />}
                <CopyButton text={data.email} />
              </td>
            </tr>
          </tbody>
        </table>
      </fieldset>

      {data.invoice_details && (                  
      <fieldset
        className="mb-12 w-fit border-solid border-2 rounded-md p-3.5"
        style={{ borderColor: "#f0a806", minWidth: "367px" }}
      >
        <legend className="px-1">Nos han escrito para pedirnos que en la facture figure lo siguiente</legend>
        {data.invoice_details}

        
      </fieldset>
      )}

      <table width="100%">
        <thead>
          <tr className="border-solid border-b border-b-black">
            <th width="12%">Unidades</th>
            <th width="60%">Concepto</th>
            <th width="16%">Prezo unitario</th>
            <th width="12%">Importe</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td colSpan={4} className="h-4"></td>
          </tr>
          {data.lines.map((line) => {
            return (
              <tr>
                <td>{line.quantity}</td>
                <td>
                  <CopyButton text={line.description} />
                </td>
                <td>
                  <CopyButton text={line.price} />
                </td>
                <td>
                  <CopyButton text={line.price} />
                </td>
              </tr>
            );
          })}

          {Array(5 - data.lines.length)
            .fill()
            .map(() => {
              return (
                <tr>
                  <td colSpan="4">&nbsp;</td>
                </tr>
              );
            })}

          <tr>
            <td></td>
            <td></td>
            <td className="w-16">Total</td>
            <td className="w-36">
              <CopyButton text={`${data.total}.00 €`} />
            </td>
          </tr>
        </tbody>
      </table>

      <fieldset className="mt-14 mb-8 text-sm border-solid border rounded-md p-3.5 border-black">
        <legend className="mb-4 px-1">Please upload invoice:</legend>
        <FileUpload
          id="invoice"
          fileSource={fileInvoice}
          handleUploadFile={(file) => setFileInvoice(file)}
          handleRemoveFile={() => {
            setFileInvoice(null);
            setBackendFileInvoice(initBackendFileInvoice);
          }}
          backendFile={backendFileInvoice.invoice_filename}
          backendFilePath={
            base_url + `/api/admin/files/${data.invoice_file_id}`
          }
          backendFileHandler={() =>
            setBackendFileInvoice(initBackendFileInvoice)
          }
          onError={(message) => setError(message)}
        ></FileUpload>
        <button
          onClick={async () => {
            setIsLoading(true);
            const formData = new FormData();
            formData.append("invoiceFile", fileInvoice);

            try {
              // Make a POST request to your authentication endpoint
              const res = await axios.post(
                `${base_url}/api/admin/invoices/${data.id}`,
                formData,
                {
                  headers: {
                    "Content-Type": "multipart/form-data",
                  },
                }
              );

              // Update state
              onSave(res.data);
            } catch (error) {
              setIsLoading(false);
              setError(error.response.data.message);
            }
          }}
          disabled={!hasChanged()}
          className={`button w-24 mt-8 ml-auto mb-8 ${
            !hasChanged() ? "!cursor-default opacity-40" : ""
          }`}
        >
          Save
        </button>
      </fieldset>
    </div>
  );
};

export default function App() {
  const columns = useMemo(
    () => [
      {
        accessorKey: "id", //simple recommended way to define a column
        header: "Invoice ID",
        muiTableHeadCellProps: { style: { color: "black" } }, //custom props
        enableHiding: false, //disable a feature for this column
      },
      {
        accessorKey: "registration_id", //simple recommended way to define a column
        header: "Registration ID",
        muiTableHeadCellProps: { style: { color: "black" } }, //custom props
        enableHiding: false, //disable a feature for this column
      },
      {
        accessorKey: "full_name", //simple recommended way to define a column
        header: "Name",
        muiTableHeadCellProps: { style: { color: "black" } }, //custom props
        enableHiding: false, //disable a feature for this column
      },
      {
        accessorKey: "email", //simple recommended way to define a column
        header: "eMail",
        muiTableHeadCellProps: { style: { color: "black" } }, //custom props
        enableHiding: false, //disable a feature for this column
      },
      {
        accessorKey: "state", //simple recommended way to define a column
        header: "State",
        muiTableHeadCellProps: { style: { color: "black" } }, //custom props
        enableHiding: false, //disable a feature for this column
        // custom conditional format and styling
        // https://www.material-react-table.com/docs/examples/advanced
        Cell: ({ cell }) => {
          return (
            cell.getValue() && (
              <Box
                component="span"
                sx={(theme) => ({
                  backgroundColor:
                    cell.getValue() === "completed"
                      ? theme.palette.success.light
                      : theme.palette.error.light,
                  borderRadius: "0.25rem",
                  color: "#fff",
                  p: "0.3rem",
                })}
              >
                {cell.getValue()}
              </Box>
            )
          );
        },
      },
    ],
    []
  );

  const [detailsRowId, setDetailsRowId] = useState(undefined);
  const [originalData, setOriginalData] = useState([]);
  const [data, setData] = useState([]);
  const [filters, setFilters] = useState({});

  const handleView = async (row) => {
    setDetailsRowId(row.getValue("id"));
  };

  const refreshData = (data, filters) => {
    const filteredData = data.filter((item) =>
      Object.entries(filters).every(([column, filterArray]) =>
        filterArray.includes(item[column])
      )
    );
    setData(filteredData);
  };

  const handleFilter = (event, column, filter) => {
    const selectedFiltersByColumn = event.target.checked
      ? { ...filters, [column]: [...(filters[column] || []), filter] }
      : { ...filters, [column]: filters[column].filter((f) => f !== filter) };

    // Remove column if its array is empty
    if (selectedFiltersByColumn[column].length === 0) {
      delete selectedFiltersByColumn[column];
    }

    setFilters(selectedFiltersByColumn);
    refreshData(originalData, selectedFiltersByColumn);
  };

  useEffect(() => {
    const loadData = async () => {
      const comm = await axios.get(`${base_url}/api/admin/invoices`);
      setData(comm.data);
      setOriginalData(comm.data);
    };

    loadData();
    /*
    let comm = [
      {
        id: "R6KRTVU",
        name: "Linas Petkevičius",
        email: "linas.mifsa.vu@gmail.com",
        type: "standard",
        state: "in progress",
        selection: [
          {
            needVisa: true,
            poster: true,
            galaDinner: true,
            farewellEvent: true,
            sponsor: true,
            womanLunch: true,
          },
        ],
      },
      {
        id: "RTVTRBD",
        name: "Francisco Cruz",
        email: "titocru@gmail.com",
        type: "standard",
        state: "in progress",
        selection: [
          {
            needVisa: true,
            poster: true,
            galaDinner: true,
            farewellEvent: false,
            sponsor: true,
            womanLunch: true,
          },
        ],
      },
      {
        id: "RAVL483",
        name: "Linas Petkevičius",
        email: "linas.petkevicius@mif.vu.lt",
        type: "standard",
        state: "completed",
        selection: [
          {
            needVisa: false,
            poster: true,
            galaDinner: true,
            farewellEvent: true,
            sponsor: false,
            womanLunch: true,
          },
        ],
      },
    ];
    */
  }, []);

  //pass table options to useMaterialReactTable
  const table = useMaterialReactTable({
    columns,
    data, //must be memoized or stable (useState, useMemo, defined outside of this component, etc.)

    enableRowSelection: false, //enable some features
    enableColumnOrdering: true, //enable a feature for all columns
    enableGlobalFilter: true, //turn off a feature
    enableRowActions: true,
    positionActionsColumn: "last",
    renderRowActions: ({ row, table }) => (
      <Box sx={{ display: "flex", flexWrap: "nowrap", gap: "8px" }}>
        <button
          className="bg-red-300 text-red-700 font-bold py-2 px-4 rounded-sm "
          onClick={() => handleView(row)}
        >
          View
        </button>
      </Box>
    ),
    muiTableHeadCellProps: {
      // simple styling with the `sx` prop, works just like a style prop in this example
      // https://www.material-react-table.com/docs/guides/customize-components#the-sx-prop
      sx: {
        backgroundColor: "#e3f1ff",
      },
    },
  });

  const downLoadExcel = async () => {
    try {
      const response = await axios.get(`${base_url}/api/admin/export_excel_invoices`, {
        responseType: "blob",
      });
      const pdfBlob = new Blob([response.data], { type: "application/xlsx" });

      const url = window.URL.createObjectURL(pdfBlob);
      const tempLink = document.createElement("a");
      tempLink.href = url;
      tempLink.setAttribute("download", `ECAI24-Invoices.xlsx`); // Set the desired filename for the downloaded file

      // Append the <a> element to the body and click it to trigger the download
      document.body.appendChild(tempLink);
      tempLink.click();

      // Clean up the temporary elements and URL
      document.body.removeChild(tempLink);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  return (
    <>
      <div className="flex flex-col v-full h-full">
        <div className="flex justify-between px-8 py-4 absolute w-full top-0 left-0">
          <div className="flex flex-wrap justify-end w-full">
            <BadgesFilter
              key={useId()}
              index={useId()}
              title="Completed"
              bgColor="#1c9d00"
              handleFilter={(e) => handleFilter(e, "state", "completed")}
            />
            <BadgesFilter
              key={useId()}
              index={useId()}
              title="Pending"
              bgColor="#FF1300"
              handleFilter={(e) => handleFilter(e, "state", "pending")}
            />
          </div>

          <div
            className=" flex flex-end cursor-pointer ml-8"
            onClick={() => downLoadExcel()}
          >
            {" "}
            <RiFileExcel2Line className="text-green-800" /> Export
          </div>
        </div>

        <div>
          <MaterialReactTable table={table} />
        </div>
      </div>

      <SidePanel
        isOpen={!!detailsRowId}
        openCloseHandler={() => setDetailsRowId(undefined)}
      >
        <div className="invoice-container">
          {detailsRowId && (
            <InvoiceDetails
              data={data.find((obj) => obj.id === detailsRowId)}
              onSave={(responseData) => {
                const { invoice_file_id, invoice_filename, state } =
                  responseData;
                // Update state with response data
                const newData = originalData.map((row) => {
                  if (row.id === detailsRowId) {
                    return {
                      ...row,
                      invoice_file_id,
                      invoice_filename,
                      state,
                    };
                  } else {
                    return row;
                  }
                });
                setOriginalData(newData);
                refreshData(newData, filters);
              }}
            />
          )}
        </div>
      </SidePanel>
    </>
  );
}
