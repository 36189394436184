import React, { useContext } from "react";
import "./style.scss";
import workshops from "../../util/workshops.json";

import { useEffect } from "react";
import RegistrationContext from "../../contexts/RegistrationContext";

function capitalizeFirstLetter(string) {
  if (!string) return string; // Return the original string if it's falsy (e.g., "", null, undefined)
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function Summary() {
  const { data, paperSelected, paperSelectedVerified } = useContext(RegistrationContext);
  useEffect(() => {
    window.history.replaceState({}, "", "/");
  }, []);

  const workshop_selection_ids = JSON.parse(data.workshop_selection);
  const selected_workshops_list = [];
  const selected_tutorials_list = [];
  const selected_dc_list = [];
  workshops.forEach((workshop) => {
    if (
      workshop_selection_ids &&
      workshop_selection_ids.includes(workshop.id) &&
      workshop.type === "Workshop"
    ) {
      selected_workshops_list.push(workshop);
    }
    if (
      workshop_selection_ids &&
      workshop_selection_ids.includes(workshop.id) &&
      workshop.type === "Tutorial"
    ) {
      selected_tutorials_list.push(workshop);
    }
    if (
      workshop_selection_ids &&
      workshop_selection_ids.includes(workshop.id) &&
      workshop.type === "DC"
    ) {
      selected_dc_list.push(workshop);
    }
  });

  let access = null;

  switch (data.access) {
    case "full":
      access = "Full access (Workshops/Tutorials/DC + Main Confefence)";
      break;
    case "main":
      access = "Main conference";
      break;
    case "workshops":
      access = "Weekend access (Workshops/Tutorials/DC)";
      break;
  }

  return (
    <div className="flex flex-col h-full grow">
      <div
        style={{ maxWidth: "1152px", width: "100%" }}
        className="flex justify-start flex-col h-full items-center mx-auto lg:py-0 text-left"
      >
        <div
          style={{ maxWidth: "1152px", width: "100%", maxHeight: "100%" }}
          className="bg-white md:mt-0 sm:max-w-md xl:p-0  "
        >
          <div className="p-6 space-y-4 md:space-y-6 sm:p-8 ">
            <h1 className="text-center px-10 pb-4 text-2xl">SUMMARY</h1>

            <div className="summary-card">
              <h2 className="mb-2">REGISTRATION TYPE</h2>

              <ul className="ml-2">
                <li>
                  Registration Id: <b>{data.registration_id}</b>
                </li>
                <li>
                  Delegate type: <b>{data.type}</b>
                </li>
                <li>
                  Conference access: <b>{access}</b>
                </li>
                <li>
                  EurAI member: <b>{data.eurai_member ? "Yes" : "No"}</b>
                </li>
              </ul>
            </div>

            <div className="summary-card">
              <h2 className="mb-2">PERSONAL DATA</h2>

              <ul className="ml-2">
                <li>
                  Name: <b>{data.full_name}</b>
                </li>
                {data.affiliation && (
                  <li>
                    Affiliation:{" "}
                    <b>
                      {data.affiliation.split("\n").map((i) => (
                        <p className="ml-8">{i} </p>
                      ))}
                    </b>
                  </li>
                )}
                <li>
                  Gender: <b>{capitalizeFirstLetter(data.gender)}</b>
                </li>
                {data.special_requirements && (
                  <li>
                    Disabilities/Special Requirements: <br />
                    <b>
                      <p className="ml-8">{data.special_requirements} </p>
                    </b>
                  </li>
                )}
              </ul>
            </div>
            {(selected_workshops_list.length > 0 ||
              selected_tutorials_list.length > 0 ||
              selected_dc_list.length > 0) && (
                <div className="summary-card">
                  <h2 className="mb-2">WORKSHOP / TUTORIAL / DC </h2>

                  {selected_workshops_list.length > 0 && (
                    <ul className="ml-2">
                      <li className="mt-4">
                        Your workshop preferences:
                        <ul className="list-disc">
                          {selected_workshops_list.map((workshop, index) => (
                            <li className="ml-8" key={index}>
                              <b>{workshop.name}</b>
                            </li>
                          ))}
                        </ul>
                      </li>
                    </ul>
                  )}
                  {selected_tutorials_list.length > 0 && (
                    <ul className="ml-2">
                      <li className="mt-4">
                        Your tutorial preferences:
                        <ul className="list-disc">
                          {selected_tutorials_list.map((workshop, index) => (
                            <li className="ml-8" key={index}>
                              <b>{workshop.name}</b>
                            </li>
                          ))}
                        </ul>
                      </li>
                    </ul>
                  )}
                  {selected_dc_list.length > 0 && (
                    <ul className="ml-2">
                      <li className="mt-4">
                        DC:
                        <ul className="list-disc">
                          {selected_dc_list.map((workshop, index) => (
                            <li className="ml-8" key={index}>
                              <b>{workshop.name}</b>
                            </li>
                          ))}
                        </ul>
                      </li>
                    </ul>
                  )}
                </div>
              )}


            {((JSON.parse(paperSelected).length > 0) || (Object.keys(JSON.parse(paperSelectedVerified)).length > 0)) && (
              <div className="summary-card">
                <h2 className="mb-2">PAPERS SELECTION</h2>

                {JSON.parse(paperSelected).length > 0 && (
                  <ul className="ml-2">
                    <li className="mt-4">
                      Your (unverified) list of registered papers:
                      <ul className="list-disc">
                        <span className="font-bold">
                          {JSON.parse(paperSelected).join(", ")}
                        </span>
                      </ul>
                    </li>
                  </ul>)}

                {(Object.keys(JSON.parse(paperSelectedVerified)).length > 0) && (
                  <>
                    <div className="mb-2">Your list of registered papers:</div>
                    <ul className="list-disc ml-4">
                      {Object.keys(JSON.parse(paperSelectedVerified)).map(key => (
                        <li key={key}>
                          <span className="font-bold">{key}:</span> {JSON.parse(paperSelectedVerified)[key]}
                        </li>
                      ))}
                    </ul>

                  </>
                )}
              </div>
            )}
            {(data.reception != "No" ||
              data.gala_dinner == "Yes" ||
              data.additional_gala_dinner > 0) && (
                <div className="summary-card">
                  <h2 className="mb-2">SOCIAL EVENTS</h2>

                  <ul className="ml-2 list-disc">
                    {data.reception == "Yes" && (
                      <li className="ml-8">
                        <b>Access to the reception event</b>
                      </li>
                    )}
                     {data.reception == "Waiting" && (
                      <li className="ml-8">
                        <b>Reception tickets are sold out. You are in a waiting list to get access</b>
                      </li>
                    )}
                    {data.gala_dinner == "Yes" && (
                      <li className="ml-8">
                        <b>Access to gala dinner confirmed</b>
                      </li>
                    )}
                    {data.additional_gala_dinner > 0 && (
                      <li className="ml-8">
                        <b>
                          {data.additional_gala_dinner} extra tickets for gala
                          dinner
                        </b>
                      </li>
                    )}
                  </ul>
                </div>
              )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Summary;
