import React, { useContext } from "react";
import "./style.scss";
import workshops from "../../util/workshops.json";

import { useEffect } from "react";
import RegistrationContext from "../../contexts/RegistrationContext";

import axios from "axios";
import { base_url } from "../../config";
import { useState } from "react";

function Summary() {
  const { data } = useContext(RegistrationContext);
  const [loading, setLoading] = useState(true);
  const [billingHistory,setBillingHistory] = useState([]);

  useEffect(() => {
    setLoading(true);
    window.history.replaceState({}, "", "/");
    axios
    .post(`${base_url}/api/get_billing`)
    .then((response) => {
      setBillingHistory(response.data);
      setLoading(false);
    })
    .catch(() => {
      setLoading(false);
     
    });
  
  }, []);
  const selected_workshops_list = [];

  workshops.forEach((workshop) => {
    if (
      data.workshop_selection &&
      data.workshop_selection.includes(workshop.id)
    ) {
      selected_workshops_list.push(workshop);
    }
  });

  const downloadInvoice = async (id) => {
    try {
      const response = await axios.get(`${base_url}/api/get_invoice/${id}`, {
        responseType: "blob",
      });
      const pdfBlob = new Blob([response.data], { type: "application/pdf" });

      const url = window.URL.createObjectURL(pdfBlob);
      const tempLink = document.createElement("a");
      tempLink.href = url;
      tempLink.setAttribute(
        "download",
        `ECAI24-Invoice-${data.registration_id}.pdf`
      ); // Set the desired filename for the downloaded file

      // Append the <a> element to the body and click it to trigger the download
      document.body.appendChild(tempLink);
      tempLink.click();

      // Clean up the temporary elements and URL
      document.body.removeChild(tempLink);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  const downloadFinalInvoice = async (id) => {
    try {
      const response = await axios.get(`${base_url}/api/get_final_invoice/${id}`, {
        responseType: "blob",
      });
      const pdfBlob = new Blob([response.data], { type: "application/pdf" });

      const url = window.URL.createObjectURL(pdfBlob);
      const tempLink = document.createElement("a");
      tempLink.href = url;
      tempLink.setAttribute(
        "download",
        `ECAI24-Invoice-${data.registration_id}.pdf`
      ); // Set the desired filename for the downloaded file

      // Append the <a> element to the body and click it to trigger the download
      document.body.appendChild(tempLink);
      tempLink.click();

      // Clean up the temporary elements and URL
      document.body.removeChild(tempLink);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };



  return (
    <div className="flex flex-col h-full grow billing">
      <div
        style={{ maxWidth: "1152px", width: "100%" }}
        className="flex justify-start flex-col h-full items-center mx-auto lg:py-0 text-left"
      >
        <div
          style={{ maxWidth: "1152px", width: "100%", maxHeight: "100%" }}
          className="bg-white md:mt-0 sm:max-w-md xl:p-0  "
        >
          <div className="p-6 space-y-4 md:space-y-6 sm:p-8 ">
            <h1 className="text-center px-10 pb-4 text-2xl">BILLING</h1>

            <div className="summary-card">
              <h2 className="mb-2">HISTORY</h2>

              {/* HISTORY */}
              <div>
                {billingHistory.map((order, index) => (
                  <div key={index} className="flex mb-4 flex-col">
                    <hr className="mt-2 mb-4" />

                    <div className="flex justify-between flex-col sm:flex-row">
                      <div className="mb-4">{order.order}</div>

                      <div className="flex gap-2 items-center">
                        <div
                          className="button"
                          onClick={() => downloadInvoice(order.invoiceId)}
                        >
                          Get Proforma
                        </div>
                        <div className="button">
                          <a href={order.receiptUrl} target="_blank">
                            Get receipt
                          </a>
                        </div>
                        {(order.finalInvoiceId !== 0) && (
                          <div
                            className="button"
                            onClick={() => downloadFinalInvoice(order.finalInvoiceId)}
                          >
                            Get Invoice
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Summary;
